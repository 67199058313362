<script setup>
import {computed, watch} from 'vue'
import { useCartStore } from '../store/cart'
import { useProductStore } from '../store/products'
import CartItem from '@/components/CartItem'
import Informer from "@/components/UI/Informer.vue";
import router from "@/router/router";
import "./Cart.vue";
import {useTelegramStore} from "@/store/telegram";
const tgStore = useTelegramStore();

const userTgId = computed(() => tgStore.user.id);
const cartStore = useCartStore();
const productStore = useProductStore();
const formattedCart = computed(() => cartStore.formattedCart);
const emit = defineEmits(['change', 'changeDeliveryType']);
let uid = userTgId.value; // 891104340;
cartStore.getSale(uid);
let sale = cartStore.sale;
let afterSaleTotal = Math.round(cartStore.total - (cartStore.total * cartStore.sale) / 100);


function changeDeliveryType(val) {
  cartStore.isDelivery = val == 'delivery' ? true : false;
}
cartStore.isDelivery = true;
const myOptions = {
  config: {
    preSelected: 'delivery',
    disabled: false,
    items: [
      { name: 'Доставка от 1000 руб. ', value: 'delivery', color: 'white', backgroundColor: 'red' },
      { name: 'Навынос от 600 руб.', value: 'pickup', color: 'white', backgroundColor: 'green' }
    ]
  }
};
let tg = window.Telegram.WebApp;
tg.BackButton.show();
tg.MainButton.text = "Оформить";
tg.MainButton.isVisible = true;
tg.MainButton.color = "#b61918";
tg.MainButton.textColor = "#fff";
tg.themeParams.secondary_bg_color ="#191918";
tg.setBackgroundColor("#191918");
tg.enableClosingConfirmation();
tg.MainButton.onClick(function() {
  tg.HapticFeedback.impactOccurred('medium');
  if(cartStore.isDelivery) {
    router.push('/checkout/delivery');
  } else {
    router.push('/checkout/pickup');
  }
});
tg.BackButton.onClick(function() {
  tg.HapticFeedback.impactOccurred('soft');
  router.back();
});
watch(
    () => cartStore.total,
    () => {
      tg.MainButton.text = "Оформить";
    },
)
</script>
<template>
  <div class="container">
    <div class="MobileCartContentLayout_root">
      <div class="MobileCartContentLayout_contentWrapper">
        <div class="MobileCartContentLayout_cartTitleRoot">
          <h1 class="UiKitText_root UiKitText_Title3Loose UiKitText_Bold UiKitText_Text"><div>Заказ</div></h1></div>
        <p style="padding: 0px 16px;font-size: 16px;font-weight: bold;" v-if="cartStore.sale">Ваша скидка за заказ составит <b>{{cartStore.sale}}%. Скидки не действуют на тематическую и праздничную продукцию.</b></p>
        <div style="padding: 10px 16px;background: #302f2d;border-radius: 15px;font-weight: bold;" class="inform"><p style="margin: 0;color: #ee3329;">Обращаем Ваше внимание, что сумма заказа не должна быть меньше минимальной суммы заказа с учетом скидки.</p></div>
        <div class="CartItems_root" v-if="formattedCart.length > 0">
          <switcher
              :titleValue="'Получение'"
              :defaultValue="'delivery'"
              :group="'deliverySwitch'"
              :options='myOptions'
              @change="changeDeliveryType($event.value)"
          />
          <informer v-if="!cartStore.isDelivery"/>
          <div data-count="1">
            <cart-item
                v-for="(cartProduct, index) in formattedCart"
                :key="index"
                :cart-product="cartProduct"
            />
            <div class="MobileCartPageAb_inner">
              <div class="MobileCartPageAb_content">
                <div class="MobileCartPageAb_title" style="color: rgb(255, 255, 255);">Скидка: {{cartStore.totalWithSale}} ₽</div>
              </div>
              <div class="MobileCartPageAb_sidebar"><span class="TemplatedPrice_root MobileCartPageAb_amount" style="color: rgb(255, 255, 255);">Сумма заказа: <span class="JsMoney">{{cartStore.total - cartStore.totalWithSale}}</span> ₽</span>
                <div class="BaseIcon_root IconUiKitIconChevronRight_root MobileCartPageAb_action UiKitIcon_m"></div>
              </div>
            </div>
          </div>
          <div class="MobileCartFooterLayout_root" v-if="false">
            <div class="MobileCartPageFooter_cartBar">
              <div class="MobileCartPageFooter_buttonWrap">
                <div class="MobileCartPageFooter_cartButtonContent">
                  <div class="MobileCartPageFooter_cartButtonPrice"><span class="Price_root" data-testid="footer-price">{{ cartStore.total }} ₽</span></div>
                </div>
                <router-link :to="{ name: 'checkout', params: { type: cartStore.isDelivery ? 'delivery' : 'pickup' } }">
                  <button class="UiKitButton_root UiKitButton_size-l UiKitButton_variant-action UiKitButton_shape-default MobileCartButton_root MobileCartPageFooter_button"><span>Верно, оформить</span></button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="text-empty" style="margin-top: 10px;" v-else>
          <p>Ваша корзина пуста. Положите товары в корзину, чтобы оформить заказ.</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  components: {
    CartItem,
    Informer
  },
  methods: {
    DeliveryTypeChange(itemVal) {
      //cartStoreC.isDelivery = itemVal == 'delivery' ? true : false;
    }
  }
}
</script>

<style scoped>
.CartItems_root {
  margin-bottom: 50px;
}
.UiKitButton_shape-default.UiKitButton_size-m, .UiKitButton_shape-default.UiKitButton_size-l {
  border-radius: 16px;
}
select.formkit-input option {
  color: white;
}
.MobileCartPageAb_inner {
  display: flex;
  padding: 8px 16px;
  min-height: 60px;
  align-items: center;
  border-bottom: 0.5px solid #444341;
}
.MobileCartPageAb_content {
  flex-grow: 1;
}
.MobileCartPageAb_title {
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
.UiKitButton_root {
  color: #E0DEDA;
  border: none;
  display: flex;
  position: relative;
  font-size: 16px;
  transition: all 0.2s ease-out;
  align-items: center;
  line-height: 17px;
  justify-content: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
.MobileCartPageFooter_button {
  height: auto;
  padding: 8px 11px;
  z-index: 1;
  min-height: 56px;
  border-radius: 16px;
}
.MobileCartPageFooter_button > span {
  display: flex;
  min-height: 1em;
  white-space: unset;
  justify-content: center;
}
.MobileCartButton_root > span {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: visible;
  position: relative;
  flex-wrap: nowrap;
  align-items: center;
  line-height: normal;
}
.MobileCartButton_root {
  width: 100%;
  display: block;
  padding: 0 16px;
  overflow: visible;
}
.UiKitButton_variant-action {
  color: #21201F;
  font-weight: 500;
  background-color: #FCE000;
}
.MobileCartPageFooter_cartButtonTime {
  font-size: 13px;
  margin-top: 3px;
  font-family: YSText, sans-serif;
  font-weight: 500;
  line-height: 15px;
  white-space: nowrap;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
.MobileCartPageFooter_cartButtonPrice {
  position: relative;
  font-size: 22px;
  font-weight: 500;
  line-height: 27px;
  white-space: nowrap;
  letter-spacing: -0.01em;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
.MobileCartPageFooter_cartButtonContent {
  display: flex;
  align-items: flex-start;
  margin-right: 35px;
  flex-direction: column;
  justify-content: center;
}
.MobileCartPageFooter_buttonWrap {
  padding-bottom: 8px;
  display: flex;
  padding: 8px 8px 8px 16px;
  padding-bottom: 8px;
  padding-bottom: max(var(--safe-area-inset-bottom, 8px), 8px);
}
.MobileCartPageFooter_cartBar {
  display: flex;
  flex-direction: column;
}
.MobileCartFooterLayout_root {
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  position: fixed;
  box-shadow: 0px -4px 20px rgba(0,0,0,0.4);
  border-radius: 24px 24px 0 0;
  background-color: #42413E;
}
.text-empty {
  display: flex;
  flex: 1;
  padding-left: 15px;

}
.MobileCartContentLayout_root {
//min-width: 100vw;
  background: #000000;
  margin-top: 0;
}
.MobileCartContentLayout_contentWrapper {
  background: #191918;
  padding-bottom: 50px;
}
.MobileCartContentLayout_cartTitleRoot {
  display: flex;
  padding: 14px 15px 6px 16px;
  background: #191918;
  align-items: center;
  border-radius: 24px 24px 0 0;
  justify-content: space-between;
}
.UiKitText_Text {
  color: #E0DEDA;
}
.UiKitText_Bold {
  font-weight: 700;
}
.UiKitText_Title3Loose {
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.015em;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

</style>