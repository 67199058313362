import Main from "@/pages/Main";
import CategoryPage from "@/pages/CategoryPage";
import ItemPage from "@/pages/ItemPage";
import Cart from "@/pages/Cart";
import Thanks from "@/pages/Thanks";
import Checkout from "@/pages/Checkout";
import Contacts from "@/pages/Contacts";
import Drawer from "@/components/UI/Drawer";
import {createRouter, createWebHistory} from "vue-router";
import Orders from "@/pages/Orders.vue";


const routes = [
	{
		path: '/',
		component: Main
	},
	{
		path: '/cart',
		component: Cart
	},
	{
		path: '/contacts',
		component: Contacts
	},
	{
		path: '/orders',
		component: Orders
	},
	{
		path: '/thanks',
		component: Thanks
	},
	{
		name: 'checkout',
		path: '/checkout/:type',
		component: Checkout,
		params: true,
	},
	{
		path: '/category/:slug/',
		component: CategoryPage,
		props: { default: true, sidebar: false },
		children: [
			{
				path: ':itemId',
				component: ItemPage,
				props: true,
				meta: {
				  showDrawer: true
				}
			}
		]
	},
]

const router = createRouter({
	routes,
	history: createWebHistory()
})

export default router;