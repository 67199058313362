<script setup>
import router from "@/router/router";
import {useCartStore} from "@/store/cart";
import {useOrderStore} from "@/store/orders";
import {useTelegramStore} from "@/store/telegram";
import {computed} from "vue";
const tgStore = useTelegramStore();
const cartStore = useCartStore();
const orderStore = useOrderStore();
cartStore.clearCart();
let tg = window.Telegram.WebApp;
var tgid = tg.initDataUnsafe.user.id;
tg.BackButton.hide();
tg.MainButton.text = "Открыть меню";
tg.MainButton.isVisible = true;
tg.MainButton.color = "#b61918";
tg.MainButton.textColor = "#fff";
tg.MainButton.onClick(function() {
  tg.HapticFeedback.impactOccurred('medium');
  router.push('/');
});
orderStore.fetchAll(tgid)
const items = computed(() => orderStore.list);

</script>

<template>
  <div class="container">
    <h1>История заказов</h1>
    <p>Последние 10 заказов за последние 90 дней</p>
    <div class="accordion" id="accordionExample" >
      <div class="accordion-item" v-if="items.length > 0" v-for="item in items">
        <h2 class="accordion-header">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse'+item.order_id" aria-expanded="false" :aria-controls="'collapse'+item.order_id">
            Заказ #{{item.order_id}}
          </button>
        </h2>
        <div :id="'collapse'+item.order_id" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <h4 class="order-title">Состав заказа:</h4>
            <p v-if="item.delivery_type == 'delivery'">Доставка {{item.client_street}} {{item.client_home}}</p>
            <p v-else>Самовывоз</p>
            <ul class="order-items" v-for="food in item.cart_data">
              <li><span>{{food.quantity}} x {{food.title}}</span>
                <span v-if="item.delivery_type == 'delivery'">{{food.cost}} руб.</span>
                <span v-else>{{food.pickupcost}} руб.</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <p v-else>У вас пока нет заказов.</p>
    </div>
  </div>
</template>

<style scoped>
.order-items li {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 5px;
}
.order-title {
  font-size: 1.3em;
}
.order-items {
  max-width: 600px;
  padding: 0;
}
.accordion-button.collapsed {
  background: #302F2D;
  color: white;
  font-weight: bold;
}
.accordion-button {
  background: #42413E !important;
  color: white;
  font-weight: bold;
}
.accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  color: white;
}

.accordion-button:not(.collapsed) {
  color: white;
  background-color: transparent;
  box-shadow: none;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}


</style>