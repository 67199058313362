import { defineStore } from 'pinia'
import { useProductStore } from './products'
import { CART_STORAGE } from '@/composables/usePersistCart'
const fakeStoreUrl = 'https://proviant-omsk.ru/api'

export const useCartStore = defineStore({
  id: 'cart',

  state: () => ({
    contents: JSON.parse(localStorage.getItem(CART_STORAGE)) ?? {},
    isDelivery: true,
    initLoad: false,
    deliveryMin: 1000,
    pickupMin: 600,
    sale: null,
    haveSaleDisabled: false,
  }),

  getters: {
    count(state) {
      return Object.keys(this.contents).reduce((acc, id) => {
        return acc + this.contents[id].quantity
      }, 0)
    },
    total(state) {
      const products = useProductStore()
      return Object.keys(this.contents).reduce((acc, id) => {
        var price = this.isDelivery ? this.contents[id].price : this.contents[id].priceSelfdelivery;
        return acc + price * this.contents[id].quantity
      }, 0)
    },
    totalWithSale(state) {
      const products = useProductStore();
      let totalSaleAmount = 0;
      return Object.keys(this.contents).reduce((acc, id) => {
        var price = this.isDelivery ? this.contents[id].price : this.contents[id].priceSelfdelivery;
        if(this.contents[id].tgsaleactive == "1") this.haveSaleDisabled = true;
        if(this.sale !== null) {
          if(this.contents[id].tgsaleactive == "0") {
            totalSaleAmount += Math.round(((acc + price * this.contents[id].quantity * this.sale) / 100));
          }
          return totalSaleAmount;
        } else {
          if(this.contents[id].tgsaleactive == "0") {
          totalSaleAmount += Math.round(((acc + price * this.contents[id].quantity * 5) / 100));
          }
          return totalSaleAmount;
        }

      }, 0)
    },
    formattedCart() {
      const products = useProductStore()
      return Object.keys(this.contents).map((productId) => {
        const purchase = this.contents[productId]
        return {
          id: purchase.productId,
          pictures: purchase.pictures,
          title: purchase.title,
          code: purchase.code,
          tgsaleactive: purchase.tgsaleactive,
          quantity: purchase.quantity,
          cost: purchase.quantity * purchase.price,
          pickupcost: purchase.quantity * purchase.priceSelfdelivery,
        }
      })
    },
  },

  actions: {
    getItemCount(productId) {
      if (this.contents[productId]) {
        return this.contents[productId].quantity;
      } else {
        return 0;
      }
    },
    add(product) {
      var productId = product.id; 
      var productPrice = parseInt(product.price); 
      var productPricePickup = parseInt(product.priceSelfdelivery); 
      if (this.contents[productId]) {
        this.contents[productId].quantity += 1
      }
      else {
        this.contents[productId] = {
          productId,
          title: product.title,
          code: product.code,
          tgsaleactive: product.tgsaleactive,
          pictures: product.pictures,
          priceSelfdelivery: productPricePickup,
          price: productPrice,
          quantity: 1,
        }
      }
    },
    clearCart() {
      Object.keys(this.contents).forEach((k) => delete this.contents[k]);
    },
    async getSale(userid) {
      const res = await fetch(`${fakeStoreUrl}/getSale/${userid}`)
      const data = await res.json()
      this.sale = data;
      console.log(data);
    },
    remove(product) {
      var productId = product.id; 
      if (!this.contents[productId])
        return
      this.contents[productId].quantity -= 1

      if (this.contents[productId].quantity === 0)
        delete this.contents[productId]
    },
  },
})
