import { defineStore } from 'pinia'
import { useCartStore } from '@/store/cart'
const fakeStoreUrl = 'https://proviant-omsk.ru/api'


export const useOrderStore = defineStore({
    id: 'orders',

    state: () => ({
        items: {},
        ids: [],
    }),

    getters: {
        list(state) {
            const cartStore = useCartStore();
            return this.ids.map(i => this.items[i])
        },

    },

    actions: {
        async fetchAll(tgid) {
            const res = await fetch(`${fakeStoreUrl}/allorders/${tgid}`)
            const data = await res.json()
            this.ids = data.map((order) => {
                this.items[order.id] = order
                return order.id
            })
        },
        // async fetchItem(itemId) {
        //     const res = await fetch(`${fakeStoreUrl}/getItem/${itemId}`)
        //     const data = await res.json()
        //     this.loadedItem = data
        //     return data.id
        // }


    },
});
