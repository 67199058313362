<script setup>
import { useCartStore } from '@/store/cart'
const cartStore = useCartStore()
</script>
<template>
	<div class="MobileSwippableListItem_root MobileSwippableListItem_withoutBackground">
		<div class="MobileSwippableListItem_background" style="right: -85px;">
			<button class="MobileEatsDeleteButton_root" style="width: 85px;"><img src="https://yastatic.net/s3/eda-front/www/assets/mobile.trash.085cf263afaf4886a80b.svg" class="UiKitUiKitIcon_m UiKitUiKitIcon_root MobileEatsDeleteButton_icon"></button>
		</div>
		<div class="MobileSwippableListItem_content">
			<div class="MobileCartItemLayout_root MobileCartItemLayout_last">
				<div class="UiKitProductCardRow_root">
					<div class="UiKitProductCardRow_imageWrapper">
						<div class="SmartImage_root MobileCartPageItem_itemImageWrapper SmartImage_hideCover">
							<div class="SmartImage_cover"></div><img :src="cartProduct.pictures" class="SmartImage_image SmartImage_coverFitImg SmartImage_visibleImg SmartImage_animatedImg" data-testid="smart-image-img" loading="lazy"></div>
					</div>
					<div class="UiKitProductCardRow_info">
						<div class="UiKitProductCardRow_name UiKitText_root UiKitText_Caption1 UiKitText_Regular UiKitText_Text UiKitText_withLineClamp" style="-webkit-line-clamp: 2;">{{ cartProduct.title }}</div>
						<div class="UiKitProductCardRow_priceRow"><span class="UiKitPrice_root UiKitPrice_s UiKitPrice_default UiKitProductCardRow_price UiKitText_root UiKitText_Body2 UiKitText_Medium UiKitText_Text">{{ cartStore.isDelivery ? cartProduct.cost : cartProduct.pickupcost }} ₽ <span style="font-size: 11px; color: red;" v-if="cartProduct.tgsaleactive == '1'">Скидка не действует</span></span></div>
					</div>
					<div class="UiKitProductCardRow_counterWrapper">
						<div class="UiKitCounter_root ProductCardRowCounterEats_counter UiKitCounter_xs UiKitCounter_default">
							<button class="UiKitCounter_button UiKitCounter_decrement" @click="cartStore.remove(cartProduct)">
								<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="UiKitUiKitIcon_m UiKitUiKitIcon_root UiKitCounter_icon">
									<path fill-rule="evenodd" clip-rule="evenodd" d="M6 12a1 1 0 0 0 1 1h10a1 1 0 1 0 0-2H7a1 1 0 0 0-1 1Z" fill="currentColor"></path>
								</svg>
							</button><span class="UiKitCounter_valueContainer"><b>{{ cartProduct.quantity }}</b></span>
							<button class="UiKitCounter_button UiKitCounter_increment UiKitCounter_squareIncrement" @click="cartStore.add(cartProduct)">
								<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="UiKitUiKitIcon_m UiKitUiKitIcon_root UiKitCounter_icon">
									<path fill-rule="evenodd" clip-rule="evenodd" d="M12 6a1 1 0 0 0-1 1v4H7a1 1 0 1 0 0 2h4v4a1 1 0 1 0 2 0v-4h4a1 1 0 1 0 0-2h-4V7a1 1 0 0 0-1-1Z" fill="currentColor"></path>
								</svg>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'CartItem',
		props: ['cartProduct']

	}
</script>

<style scoped>
.SmartImage_image {
	width: 100%;
	object-fit: contain;
	display: flex;
	height: 100%;
	border-radius: 25px;
}
.MobileSwippableListItem_withoutBackground.MobileSwippableListItem_root {
  background: transparent;
}
.MobileSwippableListItem_root {
  width: 100%;
  overflow: hidden;
  position: relative;
  background: #FA3E2C;
}
.Price_root {
  font-size: inherit;
  line-height: inherit;
  white-space: nowrap;
}

.MobileSwippableListItem_background {
  top: 0;
  bottom: 0;
  display: flex;
  position: absolute;
  will-change: transform;
}
.MobileEatsDeleteButton_root {
  border: none;
  height: 100%;
  display: block;
  padding: 0;
  position: relative;
  background: transparent;
}
.MobileEatsDeleteButton_icon {
  top: 0;
  left: 0;
  right: 0;
  width: 24px;
  bottom: 0;
  height: 24px;
  margin: auto;
  display: block;
  position: absolute;
  background-size: 24px 24px;
}
.MobileSwippableListItem_content {
  width: 100%;
  position: relative;
  will-change: transform;
}
.MobileCartItemLayout_root.MobileCartItemLayout_last::after {
  left: 16px;
}
.MobileCartItemLayout_root::after {
  left: 93px;
  right: 16px;
  bottom: 0;
  content: "";
  position: absolute;
  border-bottom: 0.5px solid rgba(245,244,242,0.2);
}
.MobileCartItemLayout_root.MobileCartItemLayout_last {
  padding-bottom: 8px;
}
.MobileCartItemLayout_root {
  position: relative;
  background: #191918;
}
.UiKitProductCardRow_root {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  border-radius: 10px;
  justify-content: space-between;
  background-color: #191918;
}
.UiKitProductCardRow_imageWrapper {
  position: relative;
  align-self: flex-start;
  border-radius: 18px;
}
.MobileCartPageItem_itemImageWrapper {
  width: 69px;
  height: 69px;
  border-radius: 16px;

}
.UiKitProductCardRow_info {
  flex-grow: 1;
  margin-left: 8px;
}
.UiKitProductCardRow_name {
  color: #E0DEDA;
  margin: 6px 0 8px;
}
.UiKitText_withLineClamp {
/*  display: -webkit-box;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;*/
}
.UiKitProductCardRow_priceRow {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  margin-top: 4px;
  align-items: flex-end;
}
.UiKitProductCardRow_priceRow > .UiKitProductCardRow_price {
  font-size: 13px;
  line-height: 14px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
.UiKitProductCardRow_priceRow > * {
  white-space: nowrap;
}
.UiKitProductCardRow_counterWrapper {
  display: flex;
  min-width: 100px;
  align-self: center;
  flex-shrink: 0;
  margin-left: 8px;
  flex-direction: column;
}
.UiKitCounter_root {
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 13px;
  align-items: center;
  line-height: 14px;
  white-space: nowrap;
  border-radius: 16px;
  justify-content: space-between;
  background-color: #302F2D;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

</style>